<template>
    <div class="table">
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item><i class="el-icon-lx-cascades"></i> 主推商品管理</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="handle-box">
                <!--<el-button type="danger" icon="delete" class="handle-del mr10" @click="delAll">批量删除</el-button>-->
                <!--<el-select v-model="select_cate" placeholder="筛选省份" class="handle-select mr10">-->
                    <!--<el-option key="1" label="广东省" value="广东省"></el-option>-->
                    <!--<el-option key="2" label="湖南省" value="湖南省"></el-option>-->
                <!--</el-select>-->
                <el-input v-model="select_word" placeholder="筛选关键词" class="handle-input mr10"></el-input>
                <el-button type="primary" icon="search" @click="search">搜索</el-button>
                <el-button type="primary" style="float: right" @click="handleEdit(undefined, undefined, 1)">添加主推商品</el-button>
            </div>
            <el-row :gutter="20">
                <el-col style="margin-bottom: 20px" :span="4" v-for="(item, index) in tableData" :key="index">
                    <el-card :body-style="{ padding: '0px' }" shadow="hover">
                        <img :src="item.imgUrl" class="image">
                        <div style="padding: 14px;">
                            <span>{{item.name}}</span>
                            <div class="bottom clearfix">
                                <div class="time">{{ item.title }}</div>
                                <el-button type="text" style="color: red" class="button" @click="deleteHotGoods(item.id)">删除此主推商品</el-button>
                            </div>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
        </div>

        <!-- 编辑弹出框 -->
        <el-dialog title="添加主推商品" v-loading="loading" :visible.sync="editVisible" width="90%">
            <el-row style="margin-bottom: 30px">
                <el-input v-model="select_word" placeholder="筛选关键词" class="handle-input mr10"></el-input>
                <el-button type="primary" icon="search" @click="search2">搜索</el-button>
            </el-row>
            <el-row :gutter="20">
                <el-col style="margin-bottom: 20px" :span="4" v-for="(item, index) in goodsList" :key="index">
                    <el-card :body-style="{ padding: '0px' }" shadow="hover">
                        <img :src="item.imgUrl" class="image">
                        <div style="padding: 14px;">
                            <span>{{item.name}}</span>
                            <div class="bottom clearfix">
                                <div class="time">{{ item.title }}</div>
                                <el-button type="text" v-if="item.ishot" class="button button2"><i class="el-icon-check"></i>已设置为主推商品</el-button>
                                <el-button type="text" v-else class="button" @click="setHotGoods(index)">设置为主推商品</el-button>
                            </div>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
            <span slot="footer" class="dialog-footer">
                <el-button @click="hideEditVisible">取 消</el-button>
                <!--<el-button type="primary" @click="saveEdit('form')">确 定</el-button>-->
            </span>
        </el-dialog>

        <!-- 删除提示框 -->
        <el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
            <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="delVisible = false">取 消</el-button>
                <el-button type="primary" @click="deleteRow">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: 'basetable',
        data() {
            return {
                url: './vuetable.json',
                tableData: [],
                cur_page: 1,  //当前页
                number: 10,  //每页显示条数
                sumPage:10,  //总页数
                multipleSelection: [],
                select_cate: '',
                select_word: '',
                del_list: [],
                is_search: false,
                editVisible: false,
                delVisible: false,
                form: {
                    id:'',
                    title: '',
                    imgid: '',
                    imgUrl: '',
                    integral:'',
                    remarks: '',
                    datetime: '',
                },
                idx: -1,
                dialogVisible: false,
                AddOrSave:'',  //1表示添加，2表示更新
                loading:false,
                rules: {
                    title: [
                        { required: true, message: '请输入奖品名称', trigger: 'blur' }
                    ],
                    integral: [
                        { required: true, message: '请输入兑换所需积分', trigger: 'blur' }
                    ],
                },
                goodsList: [],
            }
        },
        created() {
            //获取初始数据
            this.getData();
        },
        computed: {
            data() {
                return this.tableData.filter((d) => {
                    let is_del = false;
                    for (let i = 0; i < this.del_list.length; i++) {
                        if (d.prize === this.del_list[i].prize) {
                            is_del = true;
                            break;
                        }
                    }
                    return d;
                    // if (!is_del) {
                    //     if (d.b_datetime.indexOf(this.select_cate) > -1 &&
                    //         (d.b_titile.indexOf(this.select_word) > -1 ||
                    //             d.b_datetime.indexOf(this.select_word) > -1)
                    //     ) {
                    //         return d;
                    //     }
                    // }
                })
            }
        },
        methods: {
            //设置上传图片接口地址
            uploadUrl(){
                var url=this.$api.uploadUrl + "/Images/upload";
                return url
            },
            //图片上传之前
            beforeAvatarUpload(file){
                // console.log(file);
                this.loading=true;
            },
            //正在上传中
            uploading(event, file, fileList){
                // console.log(event);
                // console.log(file);
                // console.log(fileList);
            },
            //图片上传失败
            uploadError(err){
                this.$message.error(err.msg);
            },
            //图片上传成功
            handleAvatarSuccess(res, file){
                this.loading=false;
                // console.log(res);
                this.form.imgid=res.data;
                this.form.imgUrl = URL.createObjectURL(file.raw);
                this.getData();
                this.$message.success(res.msg);
            },
            // 分页导航
            handleCurrentChange(val) {
                this.cur_page = val;
                this.getData();
            },
            // 获取 easy-mock 的模拟数据
            getData() {
                // 开发环境使用 easy-mock 数据，正式环境使用 json 文件
                // if (process.env.NODE_ENV === 'development') {
                //     this.url = '/ms/table/list';
                // };
                //不传参数要写null
                var params=this.$qs.stringify({
                    select_word: this.select_word,
                    pageIndex: this.cur_page,
                    number: this.number
                });
                // console.log(params);
                this.$api.post('FissionGoods/getHotGoodsList', params, res => {
                    this.tableData = res.data;
                    // console.log(this.tableData);
                }, err => {
                    this.tableData = [];
                    this.$message.error(err.msg);
                });
                // this.$axios.post(this.url, {
                //     page: this.cur_page
                // }).then((res) => {
                //     this.tableData = res.data.list;
                //     console.log(this.tableData);
                // })
            },
            // 获取全部已上架商品
            getGoodsList() {
                //不传参数要写null
                var params=this.$qs.stringify({
                    select_word: this.select_word,
                });
                // console.log(params);
                this.$api.post('FissionGoods/getGoodsList', params, res => {
                    this.goodsList = res.data;
                }, err => {
                    this.goodsList = [];
                    this.$message.error(err.msg);
                });
            },
            search() {
                this.is_search = true;
                this.getData();
            },
            search2() {
                this.is_search = true;
                this.getGoodsList();
            },
            formatter(row, column) {
                return row.title;
            },
            filterTag(value, row) {
                return row.tag === value;
            },
            //设置为主推商品
            setHotGoods(index, row){
                const item = this.goodsList[index];
                var params=this.$qs.stringify({
                    goodsid: item.id,
                });
                this.$api.post('FissionGoods/setHotGoods', params, res => {
                    this.getGoodsList();
                    this.getData();
                    this.$message.success(res.msg);
                }, err => {
                    this.$message.error(err.msg);
                });
            },
            //删除主推商品
            deleteHotGoods(id){
                const msg="您确定要删除此主推商品嘛？";
                this.$confirm(msg, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    var params=this.$qs.stringify({
                        id: id,
                    });
                    this.$api.post('FissionGoods/deleteHotGoods', params, res => {
                        this.getData();
                        this.$message.success(res.msg);
                    }, err => {
                        this.$message.error(err.msg);
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '操作已取消'
                    });
                });

            },
            handleEdit(index, row, status) {
                this.getGoodsList();
                this.AddOrSave=status;
                //如果是添加则把form清空
                if(status==1){
                    this.form = {
                        id:null,
                        title: null,
                        imgid: null,
                        imgUrl: null,
                        integral:null,
                        remarks: null,
                        datetime: null,
                    };
                }
                if(index!=undefined && row!=undefined){
                    this.idx = index;
                    const item = this.tableData[index];
                    this.form = {
                        id:item.id,
                        title: item.title,
                        imgid: item.imgid,
                        imgUrl: item.imgUrl,
                        integral:item.integral,
                        remarks: item.remarks,
                        datetime: item.datetime,
                    };
                }
                this.editVisible = true;
            },
            handleDelete(index, row) {
                this.idx = index;
                this.form = row;
                this.delVisible = true;
            },
            delAll() {
                const length = this.multipleSelection.length;
                let str = '';
                this.del_list = this.del_list.concat(this.multipleSelection);
                for (let i = 0; i < length; i++) {
                    str += this.multipleSelection[i].b_title + ' ';
                }
                // console.log(this.del_list);
                // this.$message.error('删除了' + str);
                // this.multipleSelection = [];
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            // 保存编辑
            saveEdit(formName) {
                // this.$set(this.tableData, this.idx, this.form);
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.editVisible = false;
                        var params=null;
                        //1表示添加，2表示更新
                        if(this.AddOrSave==1){
                            params=this.$qs.stringify({
                                title: this.form.title,
                                imgid: this.form.imgid,
                                integral: this.form.integral,
                                remarks: this.form.remarks
                            });
                        }else{
                            params=this.$qs.stringify({
                                id: this.form.id,
                                title: this.form.title,
                                imgid: this.form.imgid,
                                integral: this.form.integral,
                                remarks: this.form.remarks
                            });
                        }
                        // console.log(params);
                        // return;
                        this.$api.post('FissionPrize/savePrize', params, res => {
                            // console.log(res);
                            this.getData();
                            this.$message.success(res.msg);
                        }, err => {
                            this.$message.error(err.msg);
                        });
                    }else{
                        console.log("请填写所需数据");
                        return false;
                    }
                });
            },
            // 确定删除
            deleteRow(){
                // console.log(this.form);
                // return;
                var params=this.$qs.stringify({
                    id: this.form.id
                });
                this.$api.post('FissionPrize/deletePrize', params, res => {
                    this.getData();
                    this.$message.success(res.msg+res.data+"条数据");
                }, err => {
                    this.$message.error(err.msg);
                });
                this.delVisible = false;
            },
            hideEditVisible(){
                this.editVisible=false;
            }
        }
    }

</script>

<style scoped>
    .handle-box {
        margin-bottom: 20px;
    }

    .handle-select {
        width: 120px;
    }

    .handle-input {
        width: 300px;
        display: inline-block;
    }
    .del-dialog-cnt{
        font-size: 16px;
        text-align: center
    }
    .table{
        width: 100%;
        font-size: 14px;
    }
    .red{
        color: #ff0000;
    }
    .mr10{
        margin-right: 10px;
    }
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 100%;
        /*height: 100%;*/
        display: block;
    }
    .time {
        font-size: 13px;
        color: #999;
        line-height: 15px;
        margin-bottom: 10px;
        display: -webkit-box;/*作为弹性伸缩盒子模型显示*/
        -webkit-line-clamp: 1; /*显示的行数；如果要设置2行加...则设置为2*/
        overflow: hidden; /*超出的文本隐藏*/
        text-overflow: ellipsis; /* 溢出用省略号*/
        -webkit-box-orient: vertical;/*伸缩盒子的子元素排列：从上到下*/

    }

    .bottom {
        margin-top: 13px;
        line-height: 12px;
    }

    .button {
        padding: 0;
        float: right;
    }
    .button2{
        color: green !important;
    }
    .image {
        width: 100%;
        display: block;
    }

    .clearfix:before,
    .clearfix:after {
        display: table;
        content: "";
    }

    .clearfix:after {
        clear: both
    }
</style>
